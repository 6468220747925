import { Injectable, inject } from "@angular/core";
import { Platform } from "@ionic/angular/standalone";

@Injectable({ providedIn: 'root' })
export class PlatformService {
  private readonly platform: Platform = inject(Platform);

  isWeb() {
    const result = this.platform.is('mobileweb') || this.platform.is('desktop');
    console.log(`info--- Web: ${result}`);
    return result;
  }

  isMobile() {
    const result = this.platform.is('mobile') && !this.platform.is('mobileweb');
    console.log(`info--- Mobile: ${result}`);
    return result;
  }
}