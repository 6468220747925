import { Injectable, inject } from "@angular/core";
import { ToastController } from "@ionic/angular/standalone";

@Injectable({ providedIn: 'root' })
export class ToastService {
  private readonly toastCtrl: ToastController = inject(ToastController);

  async present(type: 'success' | 'info' | 'danger', message: string, position: 'top' | 'bottom' | 'middle' = 'bottom', duration: number = 1500) {
    const toast = await this.toastCtrl.create({
      message,
      duration,
      position,
      cssClass: `ctoast-${type}`
    })
    await toast.present();
  }
}