import { Injectable } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from "@angular/forms";

/**
 * This class is responsible for conversion of an Object to a nested formgroup and setting of validation rules
 */
@Injectable()
export class FormBuilderService {
  constructor(
    private fb: UntypedFormBuilder,
  ) { }

  /**
   * This converter expects a "value"
   * @param objData
   */
  public convertObjectToFormGroup(objData: any): UntypedFormGroup {
    delete objData.component;
    const formGroup = this.fb.group(this.removeArraysAndReturnClone(objData));
    this.convertGroups(objData, formGroup);
    return formGroup;
  }

  // this will remove all arrays because errors are caused when arrays passed to fb.group
  private removeArraysAndReturnClone(item: any): any {
    const newObj = JSON.parse(JSON.stringify(item));
    Object.keys(newObj).forEach(key => {
      if ('value' in newObj[key]) {
        newObj[key] = { value: newObj[key].value, disabled: newObj[key]!.disabled };
      }

      if (this.isArray(newObj[key])) {
        delete (newObj[key]);
      }
    });
    return newObj;
  }

  private convertGroups(obj: any, formGroup: any) {
    Object.keys(obj).map((key) => {
      if (this.isObject(obj[key])) {
        if (!('value' in obj[key])) {
          const newObject = this.removeArraysAndReturnClone(obj[key]);
          const newGroup = this.fb.group(newObject);
          formGroup.setControl(key, newGroup);
          this.convertGroups(obj[key], newGroup);
        }
      } else if (this.isArray(obj[key])) {
        const arrayContents = obj[key].map((item: any) => {
          const newGroup = this.fb.group(this.removeArraysAndReturnClone(item));
          this.convertGroups(item, newGroup);
          return newGroup;
        });
        const newGroup = this.fb.array(arrayContents);
        formGroup.setControl(key, newGroup);
      } else {
        formGroup.setControl(key, new UntypedFormControl({ value: obj[key].value, disabled: obj[key]!.disabled }));
      }
    });
  }

  public setValidationRules(myModel: any, form: any): void {
    const recursive = (model: any, baseForm: any) => {
      Object.keys(model).forEach(function (parentKey) {
        const parentObject = model[parentKey];
        const fc = baseForm.get(parentKey) as UntypedFormControl;
        if (fc && parentObject && parentObject.validation) {
          fc.setValidators(parentObject.validation);
        } else if (fc && parentObject && parentObject.validationAsync) {
          fc.setAsyncValidators(parentObject.validationAsync);
        } else if (fc) {
          recursive(parentObject, fc);
        }
      });
    };
    recursive(myModel, form);
  }

  // Check type
  public isArray = (a: any) => {
    return (!!a) && (a.constructor === Array);
  };

  public isObject = (a: any) => {
    return (!!a) && (a.constructor === Object);
  };
}