import { Injectable, inject } from '@angular/core';
import { Observable, from, switchMap } from 'rxjs';
import { PlatformService } from './platform.service';
import { Platform } from '@ionic/angular';
import { Geolocation } from '@capacitor/geolocation';
import { untilDestroyed } from '@shared/utilities';
import { UserLocationStore } from '@core/stores';
import { UserLocation } from '@core/models/common';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { GeolocationData } from '@core/models/requests';

@Injectable({ providedIn: 'root' })
export class LocationService {
  private readonly platform: Platform = inject(Platform);
  private readonly platformService: PlatformService = inject(PlatformService);
  private readonly userLocationStore = inject(UserLocationStore);
  private readonly httpClient: HttpClient = inject(HttpClient);

  private readonly takeUntilDestroyed = untilDestroyed();

  runAppPermissions(): void {
    from(this.platform.ready())
      .pipe(this.takeUntilDestroyed())
      .subscribe(() => {
        // Use API call as default as it is more reliable
        this.getLocationInfo()
          .pipe(this.takeUntilDestroyed())
          .subscribe({
            next: (res) => {
              const userLocation: UserLocation = {
                city: res.city,
                country: res.countryName,
                latitude: res.latitude,
                longitude: res.longitude,
                state: res.principalSubdivision,
                locality: res.locality,
              };

              this.userLocationStore.updateLocation(userLocation);
            },
            error: () => {
              // If API call fails, use geolocation
              if (this.platformService.isWeb()) {
                this.askWebLocationPerm();
              } else {
                this.askMobileLocationPerm();
              }
            },
          });
      });
  }

  private getLocationInfo(): Observable<GeolocationData> {
    return this.httpClient.get<GeolocationData>(
      environment.bigDataAPI.geocodeClientUrl
    );
  }

  private askMobileLocationPerm() {
    from(Geolocation.requestPermissions())
      .pipe(
        this.takeUntilDestroyed(),
        switchMap(() => Geolocation.getCurrentPosition())
      )
      .subscribe((position) => {
        // TODO - Implement Reverse Geocoding to get the address (city, state, country) from the coordinates
        this.userLocationStore.updateLocation({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        } as UserLocation);
      });
  }

  private askWebLocationPerm(): void {
    if (navigator.geolocation) {
      this.getCurrentLocation()
        .pipe(this.takeUntilDestroyed())
        .subscribe((position) => {
          // TODO - Implement Reverse Geocoding to get the address (city, state, country) from the coordinates
          this.userLocationStore.updateLocation({
            latitude: position.latitude,
            longitude: position.longitude,
          } as UserLocation);
        });
    } else {
      throw new Error('Geolocation is not supported.');
    }
  }

  private getCurrentLocation(): Observable<GeolocationCoordinates> {
    return new Observable<GeolocationCoordinates>((observer) => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          observer.next(position.coords);
          observer.complete();
        },
        (err) => observer.error(err)
      );
    });
  }
}
